import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { debounce } from 'lodash-es';
import { format } from 'date-fns';
import Layout from 'components/layout';
import AppointmentsListPage from 'components/appointments/ListPage';
import { fetchProgress, listAppointments } from 'services/appointment.service';
import { getProgress } from 'src/utils';
import { isOpenApi } from 'src/services/utils';
import { t } from 'src/i18n/config';

const setProgressInAppointments = (appointments, progress) => {
  const progressmap = {};
  let allprogress = [];
  Object.keys(progress).forEach((subject) => {
    const sub = progress[subject];
    allprogress = allprogress.concat(sub);
  });
  allprogress.forEach((sub) => {
    progressmap[
      `${sub.subject}-${format(sub.date, 'yyyy-MM-dd')}-${sub.time}`
    ] = sub;
  });
  appointments.forEach((a) => {
    const sub =
      progressmap[`${a.subject}-${format(a.date, 'yyyy-MM-dd')}-${a.time}`];
    console.log(sub, a);
    if (sub != null) {
      a.currentNumber = sub.currentSeq;
      a.waiting = sub.waitingCount;
    }
  });
};
const AppointmentsPage = () => {
  const [appointments, setAppointments] = useState(undefined);
  useEffect(() => {
    isOpenApi()
      ? listAppointments().then((as) => {
          setAppointments(as);
        })
      : Promise.all([listAppointments(), fetchProgress()]).then((res) => {
          const as = res[0];
          if (getProgress()) {
            setProgressInAppointments(as, res[1]);
          }
          setAppointments(as);
        });
  }, []);
  const handleRefresh = debounce(() => {
    setAppointments(undefined);
    isOpenApi()
      ? listAppointments().then((as) => {
          setAppointments(as);
        })
      : Promise.all([listAppointments(), fetchProgress()]).then((res) => {
          const as = res[0];
          if (getProgress()) {
            setProgressInAppointments(as, res[1]);
          }
          setAppointments(as);
        });
  }, 300);

  return (
    <Layout>
      <Helmet>
        <title>{t('myAppointments')}</title>
      </Helmet>
      <AppointmentsListPage
        appointments={appointments}
        loading={appointments == null}
        onRefresh={handleRefresh}
      ></AppointmentsListPage>
    </Layout>
  );
};

export default AppointmentsPage;
