import React from 'react';
import styled from 'styled-components';
import AppointmentCard from './AppointmentCard';
import { RefreshButton } from '../1TalkDesign/Buttons';
import { t } from 'src/i18n/config';

const Container = styled.div`
  margin: 32px 16px 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const RefreshSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const RefreshContent = styled.div`
  flex: auto;
  font-family: NotoSansCJKtc;
  font-size: 1rem;
  line-height: 1.54;
  letter-spacing: 0.09px;
  color: ${(props) => props.theme.colors.primary};
`;

const Empty = styled.div`
  margin: 110px 50px 0 50px;
  text-align: center;
`;

function Refresh({ onClick }) {
  return (
    <RefreshSection>
      <RefreshContent>{t('actualProgress')}</RefreshContent>
      <RefreshButton onClick={onClick}></RefreshButton>
    </RefreshSection>
  );
}

function ListPage({ appointments, onRefresh }) {
  return (
    <Container>
      <Refresh onClick={onRefresh} />
      {appointments.length === 0 && <Empty>{t('noFutureAppointments')}</Empty>}
      {appointments.map((appointment) => {
        return (
          <AppointmentCard
            key={appointment.id}
            appointment={appointment}
          ></AppointmentCard>
        );
      })}
    </Container>
  );
}

ListPage.propTypes = {};
ListPage.defaultProps = {
  appointments: [],
};

export default ListPage;
